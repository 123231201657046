import React from 'react'
import {
	Button,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Checkbox
} from "@material-ui/core";
import AlertDialog from './Dialog'
import { DELETE } from "react-admin";
import ApiProvider from "../../provider/apiProvider";
import { extractDateAndTime } from '../utils/date/DateUtils';

const ManualTable = ({ manuals, reloadPage, callEditForm }) => {

	const deleteManual = (id) => {
		ApiProvider(DELETE, `manual?id=${id}`, { id: 0 }).then((res) => {
			const data = res.data;
			data.status === 0 && reloadPage()
		});
	}

	return (
		<Grid item xs={12}>
			<Table className="itemAlign">
				<TableHead>
					<TableRow>
						<TableCell className="myTableBorder">CD</TableCell>
						<TableCell className="myTableBorder">Trailer</TableCell>
						<TableCell className="myTableBorder">Tienda(s)</TableCell>
						<TableCell className="myTableBorder">Tipo de Mercado</TableCell>
						<TableCell className="myTableBorder">Estado</TableCell>
						<TableCell className="myTableBorder">Fecha Estado</TableCell>
						<TableCell className="myTableBorder">S2S</TableCell>
						<TableCell className="myTableBorder"></TableCell>
						<TableCell className="myTableBorder"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{manuals.map(manual => {
						const { date, time } = extractDateAndTime(manual.modified)
						manual.route = String(manual.route)
						return (
							<TableRow key={manual.id}>
								<TableCell className="myTableBorder">{manual.center_distribution}</TableCell>
								<TableCell className="myTableBorder">{manual.trailer_plate}</TableCell>
								<TableCell className="myTableBorder">{manual.route}</TableCell>
								<TableCell className="myTableBorder">{manual.ambient_description}</TableCell>
								<TableCell className="myTableBorder">{manual.status}</TableCell>
								<TableCell className="myTableBorder">{`${date} ${time}`}</TableCell>
								<TableCell className="myTableBorder">
									<Checkbox checked={manual.s2s} />
								</TableCell>
								<TableCell className="myTableBorder">
									<AlertDialog passedFunction={deleteManual} item={manual.id} msg="Desea eliminar el registro?" />
								</TableCell>
								<TableCell className="myTableBorder">
									<Button size="medium" variant="contained" onClick={() => callEditForm(manual)}>Editar</Button>
								</TableCell>
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
		</Grid>
	)
}
export default ManualTable;