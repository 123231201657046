import React from "react";
import { Route } from "react-router-dom";
import Agenda from "./components/agenda/Agenda";
import TenderingDashboard from "./components/tenderingDashboard/Container";
import Optimizer from "./components/optimizer/Container";
import OptimizerRegions from "./components/optimizerRegions/Container";
import WhiteFlags from "./components/whiteFlags/Container";
import Additional from "./components/additional/Container";
import ResultsRmZone0 from "./components/resultsRmZone0/Container";
import ResultsRegions from "./components/resultsRegions/Container";
import StoreBlock from "./components/storesBlock/Container";
import Gls from "./components/gls/Container";
import Fixplanning from "./components/fixplanning/Container";
import Store from "./components/store/Container";
import Return from "./components/return/Container";
import User from "./components/user/Container";
import Ferias from "./components/ferias/Container";
import CentersAdmin from "./components/centersPanel/Container";
import Tendering from "./components/tendering/Container";
import DriversAssistance from "./components/driversAssistance/Container";
import StoreSaturation from "./components/saturation/StoreSaturation";
import CarrierSaturation from "./components/saturation/CarrierSaturation";
import Manual from "./components/manual/Container";

export default [
  <Route exact path="/agenda" component={Agenda} />,
  <Route exact path="/tenderingDashboard" component={TenderingDashboard} />,
  <Route exact path="/optimizador/:zoneId/" component={Optimizer} />,
  <Route exact path="/optimizadorRegiones/" component={OptimizerRegions} />,
  <Route exact path="/banderasBlancas" component={WhiteFlags} />,
  <Route exact path="/adicionales" component={Additional} />,
  <Route exact path="/resultados/rm-zona0" component={ResultsRmZone0} />,
  <Route exact path="/resultados/regiones" component={ResultsRegions} />,
  <Route exact path="/bloqueTiendas" component={StoreBlock} />,
  <Route exact path="/gls" component={Gls} />,
  <Route exact path="/fixplanning" component={Fixplanning} />,
  <Route exact path="/tiendas" component={Store} />,
  <Route exact path="/devolucion" component={Return} />,
  <Route exact path="/user" component={User} />,
  <Route exact path="/ferias" component={Ferias} />,
  <Route exact path="/adminCentros" component={CentersAdmin} />,
  <Route exact path="/tendering" component={Tendering} />,
  <Route exact path="/driversAssistance" component={DriversAssistance} />,
  <Route exact path="/store-saturation" component={StoreSaturation} />,
  <Route exact path="/carrier-saturation" component={CarrierSaturation} />,
  <Route exact path="/manual" component={Manual} />,
];
