import React, { Component } from "react";
import { Divider, CardContent, Card } from "@material-ui/core";

import { Title } from "react-admin";

import Table from "./GlsTable";

import ManualForm from "../manual/ManualForm"

import Filter from "../utils/filter/TableFilter";
import Snack from "../utils/snackbar/SnackBar";

import CardTitle from "../utils/cardTitle/CardTitle";

import MyLoading from "../utils/loading/Loading";
import NotFound from "../utils/notFound/NotFound";

import { logUserActivity } from "../utils/logger/Logger";

const apiUrl = process.env.REACT_APP_API_URL;

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showManualForm: false,
      permission_Gestion: [
        "trafico",
        "trafico_full",
        "operacion_trafico_1_supervisor",
        "operacion_trafico_1_full",
        "operacion_trafico_2_supervisor",
        "operacion_trafico_2_full",
      ],
      permission_Visibility: [
        "operacion_1_supervisor",
        "operacion_1_full",
        "operacion_2_supervisor",
        "operacion_2_full",
      ],

      title: "GLS",
      create: "GLS",
      zoneFilter: "",

      endpoint: "gls",

      tableContent: [],
      status: 3,

      open: false,
      message: "",
      icon: "success",

      refresh: false,
    };
  }

  handleZoneFilter = (zoneName) => {
    this.setState({
      zoneFilter: zoneName,
    });
  };

  callApi_trailersLock = (plate) => {
    fetch(`${apiUrl}/trailer-lock`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 0) {
          for (let trailer of res.data) {
            if (trailer.trailer_plate === plate) {
              this.deleteTrailerLock(trailer.id);
            }
          }
        }
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      });
  };

  deleteTrailerLock = (idToDelete) => {
    fetch(`${apiUrl}/trailer-lock/${idToDelete}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "application/json",
        Authorization: `jwt ${localStorage.getItem("token")}`,
      },
    });
    logUserActivity(`trailer-lock,DELETE,${idToDelete}`);
  };

  tryToUnlockTrailer = (plateNumber) => {
    if (typeof plateNumber === "string") {
      this.callApi_trailersLock(plateNumber);
    }
  };

  handleFilter = (tableContent) => {
    this.handleSnack(
      true,
      "success",
      "Se han cargado " + String(tableContent.length) + " filas."
    );
    this.setState({ tableContent: tableContent });
  };

  handleTableHide = (status) => {
    if (status !== 0) {
      this.setState({ open: false });
    }
    this.setState({ status: status });
  };

  handleSnack = (open, icon, message) => {
    if (
      message.includes("Se han creado GLS.") ||
      message.includes("Se han agregado")
    ) {
      this.setState({ refresh: true });
      this.setState({ refresh: false });
    }
    this.setState({ open: open, icon: icon, message: message });
  };

  getRole = () => localStorage.getItem("role");

  handleShowManualForm = () => this.setState(
    {
      showManualForm: !this.state.showManualForm
    }
  );

  componentDidMount() {
    this.setState({ permission: this.getRole() });
  }

  render() {
    if (this.state.showManualForm) {
      return <ManualForm handleShowManualForm={this.handleShowManualForm} initialData={{
        id: null,
        s2s: false,
        center_distribution: parseInt(localStorage.getItem("cd")),
        trailer_plate: null,
        route: null,
        ambient_description: null
      }} />
    }
    if (
      this.state.permission_Gestion.includes(this.state.permission) ||
      this.state.permission_Visibility.includes(this.state.permission)
    ) {
      if (this.state.refresh) {
        return (
          <center>
            <MyLoading /> <h4 className="msg">{this.state.title}</h4>
          </center>
        );
      } else {
        return (
          <Card>
            <CardContent>
              <Title title={this.state.title} />
              <CardTitle
                optional={this.state.zoneFilter}
                main={this.state.title}
              />
              <Divider />
              <br></br>

              <Filter
                name={this.state.title}
                endpoint={this.state.endpoint}
                handleFilter={this.handleFilter}
                handleTableHide={this.handleTableHide}
                handleZoneFilter={this.handleZoneFilter}
              />
              <br></br>
              <br></br>

              {this.state.status === 0 ? (
                <Table
                  tableContent={this.state.tableContent}
                  handleSnack={this.handleSnack}
                  handleAdd={this.handleAdd}
                  handleShowManualForm={this.handleShowManualForm}
                  tryToUnlockTrailer={this.tryToUnlockTrailer}
                />
              ) : null}
              {this.state.status === 1 ? (
                <center>
                  <h1>No hay resultados disponibles.</h1>
                </center>
              ) : null}
              {this.state.status === 3 ? (
                <center>
                  <MyLoading /> <h4 className="msg">{this.state.title}</h4>
                </center>
              ) : null}

              <Snack
                open={this.state.open}
                icon={this.state.icon}
                message={this.state.message}
              />
            </CardContent>
          </Card>
        );
      }
    } else {
      return <NotFound />;
    }
  }
}

export default Container;
