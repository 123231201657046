import React from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogActions
} from "@material-ui/core";


export default function AlertDialog({ passedFunction, item, msg }) {
	const [open, setOpen] = React.useState(false);

	const handleClickConfirm = () => {
		if (item) {
			passedFunction(item);
		} else {
			passedFunction();
		}
		setOpen(false);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Button size="medium" variant="contained" onClick={handleClickOpen}>Eliminar</Button>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{msg}</DialogTitle>
				<DialogActions>
					<Button size="medium" variant="contained" onClick={handleClose}>Cancelar</Button>
					<Button size="medium" variant="contained" style={{
						backgroundColor: "#d32f2f",
						color: "white"
					}} onClick={() => handleClickConfirm()} autoFocus>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
