import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Title } from "react-admin";
import { Label } from "semantic-ui-react";
import { GET_LIST, CREATE, UPDATE } from "react-admin";
import ApiProvider from "../../provider/apiProvider";
import { Card, Grid, CardContent, Divider, Button, Checkbox } from "@material-ui/core/";
import Snackbar from "../utils/snackbar/SnackBar"

const ManualForm = ({ handleShowManualForm, initialData }) => {
	const errorsAlerts = {
		ROUTE_FORMAT: 'Tiendas de ruta mal ingresadas.',
		TRAILER_FORMAT: 'Trailer no cumple con el formato',
		RETURN_ERROR: 'Trailer cuenta con devoluciones',
		CREATION_ERROR: 'Error al crear viaje manual',
		STORE_ERROR: 'La tienda no existe'
	}
	const [cdList, setCdList] = useState([]);
	const [errorMessage, setErrorMessage] = useState({
		open: false,
		message: errorsAlerts.ROUTE_FORMAT
	})
	const [ambientList, setAmbientList] = useState([]);
	const [formData, setFormData] = useState(initialData);

	const verifyRoute = (data) => {
		const pattern = /^\d+(,\d+)*$/;
		const result = pattern.test(data);
		return result;
	};

	const handleChange = (event) => {
		setErrorMessage({
			...errorMessage,
			open: false,
		})
		const { id, value } = event.target ? event.target : event
		if (id === 'route') {
			verifyRoute(value) ? setErrorMessage({
				...errorMessage,
				open: false,
			}) : setErrorMessage({
				message: errorsAlerts.ROUTE_FORMAT,
				open: true
			})
		}
		if (id === 'trailer_plate') {
			value.match(/^([a-zA-Z]{4}[\d]{2}|[a-zA-Z]{2}[\d]{4}|\d{6})$/) ?
				setErrorMessage({
					...errorMessage,
					open: false,
				}) :
				setErrorMessage({
					message: errorsAlerts.TRAILER_FORMAT,
					open: true
				})
		}
		setFormData({
			...formData,
			[id]: value
		})
	}

	const handleOnSubmit = (event) => {
		event.preventDefault();
		const { route, id, active, created, modified, status, ...data } = formData
		data.route = route.split(',').map(el => parseInt(el))
		const method = id ? UPDATE : CREATE;
		const endpoint = id ? `manual?id=${id}` : "manual";
		if (!errorMessage.open) {
			ApiProvider(method, endpoint, {
				data: data
			}).then((res) => {
				if (res.data.status === 0) {
					window.location = '/#/manual'
					window.location.reload();
				} else {
					const errorMessage = {
						'Has Returns': errorsAlerts.RETURN_ERROR,
						'Invalid Store': errorsAlerts.STORE_ERROR
					}
					setErrorMessage({
						open: true,
						message: errorMessage.hasOwnProperty(res.data.data) ?
							errorMessage[res.data.data] : errorsAlerts.CREATION_ERROR
					})
				}
			})
		}
	}

	const getCds = () => {
		ApiProvider(GET_LIST, "distribution-center", {
			pagination: { page: 1, perPage: 10 },
			sort: { field: "id", order: "ASC" },
		}).then((res) => {
			setCdList(res.data)
		});
	}

	const getAmbientDesc = () => {
		ApiProvider(GET_LIST, "ambient-desc", {
			pagination: { page: 1, perPage: 10 },
			sort: { field: "id", order: "ASC" },
		}).then((res) => {
			if (res.data.status === 0) {
				setAmbientList(res.data.data);
			}
		});
	}

	useEffect(() => {
		getCds()
		getAmbientDesc()
	}, [])

	return (
		<Card>
			<CardContent>
				<Title title="CREAR VIAJE MANUAL" />
				<h1 className="myTitle">{initialData.id ? 'MODIFICAR' : 'CREAR'} VIAJE MANUAL</h1>
				<Divider />
				<br />
				<CardContent>
					<form onSubmit={handleOnSubmit}>
						<Grid container spacing={32}>
							<Grid item xs={12} sm={12} className="inline">
								<Label className="myLabel">CD</Label>
								<Select
									id="center_distribution"
									className="select"
									classNamePrefix="select"
									defaultValue={{ value: formData.center_distribution, label: formData.center_distribution }}
									options={cdList.map((opt) => ({
										label: opt.id,
										value: opt.id,
									}))}
									onChange={(opt) => handleChange({ id: 'center_distribution', value: opt.value })}
								/>
							</Grid>
							<Grid item xs={12} sm={12} className="inline">
								<Label className="myLabel">TRAILER</Label>
								<input
									className="select form-control"
									type="text"
									id="trailer_plate"
									value={formData.trailer_plate}
									onChange={handleChange}
									required
								/>
							</Grid>
							<Grid item xs={12} sm={12} className="inline">
								<Label className="myLabel">TIENDA(S)</Label>
								<input
									className="select form-control"
									type="text"
									id="route"
									value={formData.route}
									onChange={handleChange}
									required
								/>
							</Grid>
							<Grid item xs={12} sm={12} className="inline">
								<Label className="myLabel">TIPO MERCADERIA</Label>
								<Select
									id="ambient_description"
									className="select"
									classNamePrefix="select"
									defaultValue={{ value: formData.ambient_description, label: formData.ambient_description }}
									options={ambientList.map((opt) => ({
										label: opt.ambient_desc,
										value: opt.ambient_desc,
									}))}
									onChange={(opt) => handleChange({ id: 'ambient_description', value: opt.value })}
								/>
							</Grid>
							<Grid item xs={12} sm={12} className="inline">
								<Label className="myLabel">S2S</Label>
								<Checkbox id="s2s" onChange={() => handleChange({ id: 's2s', value: !formData.s2s })} checked={formData.s2s} />
							</Grid>
						</Grid>
						<br />
						<Grid
							style={{
								marginTop: "25px"
							}}
							container
							direction="row"
							alignItems="center"
						>
							<Grid item xs={6}>
								<Button size="medium" variant="contained" onClick={handleShowManualForm}>Cancelar</Button>
							</Grid>
							<Grid item xs={6}>
								<Button size="medium" variant="contained" type="submit" color="primary">
									{initialData.id ? 'Modificar' : 'Crear'}
								</Button>
							</Grid>
						</Grid>
					</form>
				</CardContent>
				<Snackbar
					open={errorMessage.open}
					icon="error"
					message={errorMessage.message}
				/>
			</CardContent>
		</Card>
	)
}
export default ManualForm;
