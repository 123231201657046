import React, { useState } from "react";
import { connect } from "react-redux";
import {
  MenuItemLink,
  getResources,
  Responsive,
  DashboardMenuItem,
  WithPermissions,
} from "react-admin";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessIcon from "@material-ui/icons/Business";
import TruckIcon from "@material-ui/icons/LocalShipping";
import CenterIcon from "@material-ui/icons/LocationCity";
import LabelIcon from "@material-ui/icons/Label";
import FlagIcon from "@material-ui/icons/Flag";
import TenderingIcon from "@material-ui/icons/PermContactCalendar";
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import PlusIcon from "@material-ui/icons/AddCircle";
import DumpTruckIcon from "@material-ui/icons/AirportShuttle";
import ReturnIcon from "@material-ui/icons/AssignmentReturn";
import PlanningIcon from "@material-ui/icons/PlaylistAddCheck";
import StoreIcon from "@material-ui/icons/StoreMallDirectory";
import UsersIcon from "@material-ui/icons/People";
import FeriasIcon from "@material-ui/icons/BorderColor";
import DriversAssisIcon from "@material-ui/icons/AccessTime";
import StorageIcon from '@material-ui/icons/Storage';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import TodayIcon from '@material-ui/icons/Today';
import SubMenu from './SubMenu';


import { getAllRoles } from "../utils/roleManager/RoleManager";

const styles = {
  root: { fontSize: "18px" },
  active: { fontWeight: "bold", color: "goldenrod" },
  icon: { "& svg": { fontSize: "30px" } },
};

const permission_Visibility = getAllRoles().filter(role => (role !== "supervisor_tienda" && role !== "transportista"));


const MyMenu = (props) => {
  const { classes, onMenuClick, logout } = props;

  const paths = {
    'agenda': '/agenda',
    'dashboards': {
      'dashboard': '/',
      'tendering': '/tenderingDashboard'
    },
    'saturacion': {
      'storeSaturation': '/store-saturation',
      'carrierSaturation': '/carrier-saturation'
    },
    'controlPanel': '/adminCentros',
    'manual': '/manual',

    'optimizator': {
      'rm': '/optimizador/1/',
      'zone0': '/optimizador/3/',
      'optimizerRegions': '/optimizadorRegiones/',
    },

    'zone0': {
      'tendering': '/tendering',
      'driversAssistance': '/driversAssistance'
    },

    'results': {
        'rm-zone0': '/resultados/rm-zona0',
        'regions': '/resultados/regiones',
    },

    'gls': '/gls',

    'management': {
      'additionals': '/adicionales',
      'whiteFlags': '/banderasBlancas',
      'blockedStores': '/bloqueTiendas',
      'fixPlanning':'/fixplanning',
      'returns': '/devolucion'
    },

    'databases': {
      'stores': '/tiendas',
      'holidays': '/ferias'
    },

    'users': '/user'   
  }

  const isMenuOpen = menuName => {
    return Object.values(paths[menuName]).includes(props.location.pathname);
  }

  const [state, setState] = useState({
    menuDashboards: isMenuOpen('dashboards'),
    menuSaturacion: isMenuOpen('saturacion'),
    menuOptimizator: isMenuOpen('optimizator'),
    menuZone0: isMenuOpen('zone0'),
    menuResults: isMenuOpen('results'),
    menuManagement: isMenuOpen('management'),
    menuDatabases: isMenuOpen('databases')
  });

  const handleToggle = menu => {
    setState(state => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div>
      <WithPermissions
        render={({ permissions }) =>
          permissions === "transportista" && (
            <MenuItemLink
                classes={classes}
                to={paths.results.regions}
                primaryText="Regiones"
                onClick={() => {
                  if (window.location.href.endsWith("/regiones")) {
                    window.location.reload();
                  }
                }}
                leftIcon={<TruckIcon />}
              />
          ) 
        }
      />
      <WithPermissions
        render={({ permissions }) =>
          permission_Visibility.includes(permissions) && (
            <SubMenu
              handleToggle={() => handleToggle("menuDashboards")}
              isOpen={state.menuDashboards}
              sidebarIsOpen={props.open}
              name="Dashboards"
              icon={<DashboardIcon />}
            >
              <DashboardMenuItem
                classes={classes}
                onClick={onMenuClick}
                primaryText="General"
              />

              <MenuItemLink
                classes={classes}
                to={paths.dashboards.tendering}
                primaryText="Tendering"
                leftIcon={<TenderingIcon />}
              />

            </SubMenu>
          )
        }
      />
      <WithPermissions
        render={({ permissions }) =>
          permission_Visibility.includes(permissions) && (
            <SubMenu
              handleToggle={() => handleToggle("menuSaturacion")}
              isOpen={state.menuSaturacion}
              sidebarIsOpen={props.open}
              name="Saturación Regiones"
              icon={<DonutLargeIcon />}
            >
              <MenuItemLink
                classes={classes}
                to={paths.saturacion.storeSaturation}
                primaryText="Tiendas"
                leftIcon={<StoreIcon />}
              />
              <MenuItemLink
                classes={classes}
                to={paths.saturacion.carrierSaturation}
                primaryText="Transportistas"
                leftIcon={<DumpTruckIcon />}
              />
            </SubMenu>
          )
        }
      />
      <WithPermissions
        render={({ permissions }) =>
          permission_Visibility.includes(permissions) ? (
            <MenuItemLink
              classes={classes}
              to={paths.controlPanel}
              primaryText="Panel de Control"
              onClick={() => {
                if (window.location.href.includes("adminCentros")) {
                  window.location.reload();
                } else {
                  window.location.href =
                    window.location.origin + "/#/adminCentros";
                  window.location.reload();
                }
              }}
              leftIcon={<CenterIcon />}
            />
          ) : null
        }
      />

      <WithPermissions
        render={({ permissions }) =>
          permission_Visibility.includes(permissions) ? (
            <SubMenu
              handleToggle={() => handleToggle("menuOptimizator")}
              isOpen={state.menuOptimizator}
              sidebarIsOpen={props.open}
              name="Optimizador"
              icon={<TruckIcon />}
            >
              <MenuItemLink
                classes={classes}
                to={paths.optimizator.rm}
                primaryText="RM"
                onClick={() => {
                  if (window.location.href.includes("optimizador/1/")) {
                    window.location.reload();
                  } else {
                    window.location.href =
                      window.location.origin + "/#/optimizador/1/";
                    window.location.reload();
                  }
                }}
                leftIcon={<TruckIcon />}
              />

              <MenuItemLink
                classes={classes}
                to={paths.optimizator.zone0}
                primaryText="Zona 0"
                onClick={() => {
                  if (window.location.href.includes("optimizador/3/")) {
                    window.location.reload();
                  } else {
                    window.location.href =
                      window.location.origin + "/#/optimizador/3/";
                    window.location.reload();
                  }
                }}
                leftIcon={<TruckIcon />}
              />

              <MenuItemLink
                classes={classes}
                to={paths.optimizator.optimizerRegions}
                primaryText="Regiones"
                onClick={() => {
                  if (window.location.href.includes("optimizadorRegiones/")) {
                    window.location.reload();
                  } else {
                    window.location.href =
                      window.location.origin + "/#/optimizadorRegiones/";
                    window.location.reload();
                  }
                }}
                leftIcon={<TruckIcon />}
              />
            </SubMenu>
          ) : null
        }
      />

      <WithPermissions
        render={({ permissions }) =>
          permission_Visibility.includes(permissions) ? (
            <SubMenu
              handleToggle={() => handleToggle("menuZone0")}
              isOpen={state.menuZone0}
              sidebarIsOpen={props.open}
              name="Zona 0"
              icon={<TenderingIcon />}
            >
              <MenuItemLink
                classes={classes}
                to={paths.zone0.tendering}
                primaryText="Tendering"
                onClick={() => {
                  if (window.location.href.endsWith("/tendering")) {
                    window.location.reload();
                  }
                }}
                leftIcon={<TenderingIcon />}
              />

              <MenuItemLink
                classes={classes}
                to={paths.zone0.driversAssistance}
                primaryText="Asistencia"
                onClick={() => {
                  if (window.location.href.includes("driversAssistance")) {
                    window.location.reload();
                  }
                }}
                leftIcon={<DriversAssisIcon />}
              />
            </SubMenu>
          ) : null
        }
      />

      <WithPermissions
        render={({ permissions }) =>
          permission_Visibility.includes(permissions) ? (
            <SubMenu
              handleToggle={() => handleToggle("menuResults")}
              isOpen={state.menuResults}
              sidebarIsOpen={props.open}
              name="Resultados"
              icon={<LabelIcon />}
            >
              <MenuItemLink
                classes={classes}
                to={paths.results["rm-zone0"]}
                primaryText="RM y Zona 0"
                onClick={() => {
                  if (window.location.href.endsWith("/rm-zona0")) {
                    window.location.reload();
                  }
                }}
                leftIcon={<TruckIcon />}
              />
              <MenuItemLink
                classes={classes}
                to={paths.results.regions}
                primaryText="Regiones"
                onClick={() => {
                  if (window.location.href.endsWith("/regiones")) {
                    window.location.reload();
                  }
                }}
                leftIcon={<TruckIcon />}
              />
            </SubMenu>
          ) : null
        }
      />

      <WithPermissions
        render={({ permissions }) =>
          permission_Visibility.includes(permissions) ? (
            <MenuItemLink
              classes={classes}
              to={paths.gls}
              primaryText="GLS"
              onClick={() => {
                if (window.location.href.includes("gls")) {
                  window.location.reload();
                }
              }}
              leftIcon={<DumpTruckIcon />}
            />
          ) : null
        }
      />

      <WithPermissions
        render={({ permissions }) =>
          permission_Visibility.includes(permissions) ? (
            <SubMenu
              handleToggle={() => handleToggle("menuManagement")}
              isOpen={state.menuManagement}
              sidebarIsOpen={props.open}
              name="Tráfico Gestión"
              icon={<DoneAllIcon />}
            >
              <MenuItemLink
                classes={classes}
                to={paths.management.additionals}
                primaryText="Adicionales"
                onClick={() => {
                  if (window.location.href.includes("adicionales")) {
                    window.location.reload();
                  }
                }}
                leftIcon={<PlusIcon />}
              />

              <MenuItemLink
                classes={classes}
                to={paths.management.whiteFlags}
                primaryText="Banderas Blancas"
                onClick={() => {
                  if (window.location.href.includes("banderasBlancas")) {
                    window.location.reload();
                  }
                }}
                leftIcon={<FlagIcon />}
              />

              <MenuItemLink
                classes={classes}
                to={paths.management.blockedStores}
                primaryText="Bloqueo Tiendas"
                onClick={() => {
                  if (window.location.href.includes("bloqueTiendas")) {
                    window.location.reload();
                  }
                }}
                leftIcon={<BusinessIcon />}
              />

              <MenuItemLink
                classes={classes}
                to={paths.management.fixPlanning}
                primaryText="Fixplanning"
                onClick={() => {
                  if (window.location.href.includes("fixplanning")) {
                    window.location.reload();
                  }
                }}
                leftIcon={<PlanningIcon />}
              />

              <MenuItemLink
                classes={classes}
                to={paths.management.returns}
                primaryText="Devolución"
                onClick={() => {
                  if (window.location.href.includes("devolucion")) {
                    window.location.reload();
                  }
                }}
                leftIcon={<ReturnIcon />}
              />
            </SubMenu>
          ) : null
        }
      />

      <WithPermissions
        render={({ permissions }) =>
          permission_Visibility.includes(permissions) ? (
            <MenuItemLink
              classes={classes}
              to={paths.manual}
              primaryText="Manual"
              onClick={() => {
                if (window.location.href.includes("manual")) {
                  window.location.reload();
                } else {
                  window.location.href =
                    window.location.origin + "/#/manual";
                  window.location.reload();
                }
              }}
              leftIcon={<CenterIcon />}
            />
          ) : null
        }
      />

      <WithPermissions
        render={({ permissions }) =>
          permission_Visibility.includes(permissions) ? (
            <SubMenu
              handleToggle={() => handleToggle("menuDatabases")}
              isOpen={state.menuDatabases}
              sidebarIsOpen={props.open}
              name="Bases de Datos"
              icon={<StorageIcon />}
            >
              <MenuItemLink
                classes={classes}
                to={paths.databases.stores}
                primaryText="Conf. Tiendas"
                onClick={() => {
                  if (window.location.href.includes("tiendas")) {
                    window.location.reload();
                  }
                }}
                leftIcon={<StoreIcon />}
              />

              <MenuItemLink
                classes={classes}
                to={paths.databases.holidays}
                primaryText="Ferias"
                onClick={() => {
                  if (window.location.href.includes("ferias")) {
                    window.location.reload();
                  }
                }}
                leftIcon={<FeriasIcon />}
              />
            </SubMenu>
          ) : null
        }
      />

      <WithPermissions
        render={({ permissions }) =>
          permission_Visibility.includes(permissions) ? (
            <MenuItemLink
              classes={classes}
              to={paths.users}
              primaryText="Usuarios"
              onClick={() => {
                if (window.location.href.includes("usuarios")) {
                  window.location.reload();
                }
              }}
              leftIcon={<UsersIcon />}
            />
          ) : null
        }
      />
      <WithPermissions
        render={({ permissions }) =>
          permission_Visibility.includes(permissions) ||
          permissions === "supervisor_tienda" ? (
            <MenuItemLink
              classes={classes}
              to={paths.agenda}
              primaryText="Agenda"
              onClick={() => {
                if (window.location.href.includes("agenda")) {
                  window.location.reload();
                }
              }}
              leftIcon={<TodayIcon />}
            />
          ) : null
        }
      />
      <Responsive small={logout} medium={null} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  open: state.admin.ui.sidebarOpen,
  resources: getResources(state),
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(MyMenu)));
