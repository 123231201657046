import React, { useEffect, useState } from 'react';
import {
	Divider,
	CardContent,
	Card
} from "@material-ui/core";
import { GET_LIST } from "react-admin";
import { Title } from "react-admin";
import Table from './Table';
import Filter from "./Filter"
import CardTitle from "../utils/cardTitle/CardTitle";
import ApiProvider from "../../provider/apiProvider";
import ManualForm from "./ManualForm"

const Container = () => {
	const title = "Agregados de Forma Manual"
	const [manual, setManual] = useState([]);
	const [editManual, setEditManual] = useState({
		active: false,
		id: null,
		s2s: false,
		center_distribution: null,
		trailer_plate: null,
		route: null,
		ambient_description: null
	});

	const handleShowManualForm = _ => setEditManual({
		...editManual,
		active: false
	})

	const callEditForm = (manualData) => {
		setEditManual({
			...manualData,
			active: true
		})
	}

	const [manualModified, setManualModified] = useState(false);

	const [filters, setFilters] = useState({
		center_distribution: null,
		route: null
	});

	const getManuals = (filters) => {
		ApiProvider(GET_LIST, "manual", {
			filter: filters,
			pagination: { page: 1, perPage: 10 },
			sort: { field: "id", order: "ASC" },
		}).then((res) => {
			setManual(res.data.data)
		});
	}

	const reloadPage = _ => setManualModified(!manualModified);

	useEffect(() => {
		getManuals(filters)
	}, [filters, manualModified])

	if (editManual.active) {
		return (
			<ManualForm handleShowManualForm={handleShowManualForm} initialData={editManual} />
		)
	}
	return (
		<Card>
			<CardContent>
				<Title title={title} />
				<CardTitle main={title} />
				<Divider />
				<Filter setFilters={setFilters} />
				<Table manuals={manual} reloadPage={reloadPage} callEditForm={callEditForm} />
			</CardContent>
		</Card>
	)
}

export default Container;