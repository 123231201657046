import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { Label } from "semantic-ui-react";
import { GET_LIST } from "react-admin";
import ApiProvider from "../../provider/apiProvider";
import { Button, FormControl, Grid } from "@material-ui/core";

const Filter = ({ setFilters }) => {
	const [cdList, setCdList] = useState([]);
	const [selected, setSelected] = useState({
		center_distribution: null,
		route: null
	})
	const getCds = () => {
		ApiProvider(GET_LIST, "distribution-center", {
			pagination: { page: 1, perPage: 10 },
			sort: { field: "id", order: "ASC" },
		}).then((res) => {
			setCdList(res.data)
		});
	}

	const handleChange = (event) => {
		const { id, value } = event.target ? event.target : event
		setSelected({
			...selected,
			[id]: value
		})
	}

	useEffect(() => {
		getCds()
	}, [])

	return (
		<Grid container spacing={8} alignItems="flex-end">
			<Grid item>
				<FormControl>
					<Label className="myTableCenter myLabelSelect">CD</Label>
					<Select
						id="center_distribution"
						className="select"
						classNamePrefix="select"
						options={cdList.map((opt) => ({
							label: opt.id,
							value: opt.id,
						}))}
						onChange={(opt) => handleChange({ id: 'center_distribution', value: opt.value })}
					/>
				</FormControl>
			</Grid>
			<Grid item>
				<FormControl>
					<Label className="myTableCenter myLabelSelect">Tienda</Label>
					<input
						type="number"
						onChange={handleChange}
						min="0"
						id="route"
						className="select form-control"
						required
					/>
				</FormControl>
			</Grid>
			<Grid item>
				<Button
					variant="outlined"
					onClick={() => setFilters(selected)}
				>
					Filtrar
				</Button>
			</Grid>
		</Grid>
	)
}

export default Filter;
